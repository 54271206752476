/***********/
/** PurchasedInsert **/
/***********/

.container {
  display: flex;
  justify-content: space-between;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

/* Classi per le larghezze */
.width-13 {
  width: 13%;
}

.width-52 {
  width: 52%;
}

.width-20 {
  width: 20%;
}

.width-15 {
  width: 15%;
}

.width-50 {
  width: 50%;
}

/* Stili del testo */
.text-blue {
  color: #1976d2;
}

.font-calibri {
  font-family: "Calibri", sans-serif;
}

.font-size-22 {
  font-size: 22px;
}

.bold {
  font-weight: bold;
}

/***********/
/** PurchasedForm **/
/***********/
/* TODO: Capire lo spazio tra i due inserimenti di data */
.form-title,
.form-subtitle {
  font-family: "Calibri";
  color: #1976d2;
}

.form-subtitle {
  padding-top: 2%;
}

.form-date-container {
  padding-top: 2%;
  display: flex;
  justify-content: center;
  align-items: center; /* Center date fields */
}

.form-date-field {
  width: 48%;
  text-align: center; /* Center content within date fields */
}

.form-date-spacing {
  width: 4%; /* Spacing between date fields */
}

.form-error-message {
  padding-top: 1%;
  color: #ff0000;
  font-family: "Calibri";
  font-size: 20px;
}

.form-submit-button {
  padding-top: 2.5%;
  text-align: center; /* Center the submit button */
}

/***********/
/** PurchasedImport **/
/***********/
.text-right {
  text-align: right;
  width: 50%;
}

/* Stili per i Box */
.box {
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1%;
}

.box-large,
.box-medium {
  padding-left: 5%;
  padding-right: 5%;
}

.box-large {
  height: 500px;
}

.box-medium {
  height: 418px;
}

.box-small {
  height: 385px;
}

/* Temi delle celle */
.theme-header {
  background-color: #1976d2;
  color: #fff;
  font-family: "Calibri";
  font-size: 16px;
}

.theme-cell {
  font-family: "Calibri";
  font-size: 17px;
  border: 1px solid;
}

.theme-cell-error {
  background-color: #f00;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #000;
}

.theme-cell-nomatch {
  font-size: 18px;
  color: #ff1a00;
}

/* Stili per il titolo degli errori */
.error-title {
  text-align: center;
  color: #f00;
  font-family: "Calibri";
  padding-top: 1%;
}

/* Contenitore pulsante */
.button-container {
  text-align: left;
  display: inline-block;
  width: 100%;
}

/* Stili per etichette */
.label-checked {
  font-family: "Calibri";
  color: #1976d2;
}

.label-unchecked {
  font-family: "Calibri";
  color: #ccc;
}
