* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

/*Colore testo header, vedere boxshadow!*/
header {
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05); */
  background-color: #1976d2;
  color: #fff;
}

/*navbar*/
.nav-area {
  display: flex;
  align-items: center;
  background-color: #1976d2;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0.5% 1%;
}

/*LOGO*/
.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 10%;
}

.user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  list-style: none;
}

.user-items {
  position: relative;
  font-size: 14px;
}

.user-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.user-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.user-items > a,
.user-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.user-items a:hover,
.user-items button:hover {
  background-color: #1566b7;
}

/**/
.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
  width: 150px;
  /* margin-left: 50px; */
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  border-left: 1px solid #fff;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #1566b7;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

/*Cambia allinemanto dropdown*/
.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #1976d2;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

/***********/
/*STILI PAGINE ACQUISTATO*/
.acqPage {
  margin: 1%;
  text-align: center;
}

.singleOfferPage {
  margin: 1%;
  text-align: center;
}

.allOfferPage {
  margin: 1%;
  text-align: center;
}

.abbPage {
  margin: 1%;
  text-align: center;
}

.scadenzePage {
  margin: 1%;
  text-align: center;
}

.sidebar {
  width: 95%;
  background-color: #1976d2;
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 100%;
  z-index: 1;
  left: 0;
}

.sidebar-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar-icon {
  margin-top: 2%;
  opacity: 0.5; /* Make the icon less prominent */
  /* filter: blur(2px); Blur the icon */
  /* Ensure that the icon is centered beneath the text if larger than text */
  display: flex;
  justify-content: center;
  width: 100%;
}

.sidebar-text {
  margin-top: 2%;
}

.menus-home {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-home-items {
  position: relative;
  font-size: 22px;
  width: 150px;
  margin-left: 50px;
}

.menu-home-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-home-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  border-left: 1px solid #fff;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-home-items > a,
.menu-home-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-home-items a:hover,
.menu-home-items button:hover {
  background-color: #1566b7;
}

.home-page-login {
  width: 100%;
  height: 79vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  /* background-color: #333;
  color: #fff; */
  background-color: #1976d2;
  color: #fff;
  padding: 10px 10px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 11vh;
  /* font-family: "Times New Roman", Times, serif; */
  font-family: "Calibri", Arial, sans-serif;
  font-size: 0.9em;
}

.footer-left-content {
  display: inline-block;
  width: 70%;
  text-align: left;
  font-size: 22px;
  margin-top: 0.5%;

  /* font-weight: bold; */
}

.footer-right-content {
  display: inline-block;
  width: 30%;
  text-align: right;
  font-size: 22px;
  margin-top: 0.5%;
}

.footer-link {
  color: #fff;
  text-decoration: none;
}

.login .formContainer .MuiTextField-root {
  width: 100%;
}

.inputField {
  padding-top: 1%;
  text-align: center; /* Centra i contenuti */
}

.inputField .MuiTextField-root {
  width: 100%; /* Usa tutta la larghezza disponibile del genitore */
  max-width: 350px; /* Non supera i 400px */
  margin: auto; /* Centra il campo se è più piccolo del genitore */
}

/* Media query per schermi più piccoli */
@media (max-width: 768px) {
  .inputField .MuiTextField-root {
    max-width: 300px; /* Larghezza massima più piccola per schermi più piccoli */
  }
}

/* Media query per schermi ancora più piccoli */
@media (max-width: 480px) {
  .inputField .MuiTextField-root {
    max-width: 250px; /* Larghezza massima ancora più piccola per schermi molto piccoli */
  }
}

.loginHeader {
  padding-top: 5%;
  font-family: Calibri, sans-serif;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  color: #1976d2;
}

.submitButton {
  padding-top: 1%;
  text-align: center;
}

/*aggiunte*/
.button-container-acqChangePage {
  text-align: right;
  margin-bottom: 1%;
}

/* InfoPage.css */

.info-container {
  display: flex;
  flex-direction: column; /* Disposizione verticale */
  justify-content: center;
  align-items: center;
  height: 60vh; /* Centra il riquadro verticalmente */
  color: #1976d2;
  font-size: 24px;
}

.info-box {
  background-color: #1976d2; /* Colore di sfondo del riquadro */
  color: #fff; /* Colore del testo */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Effetto ombra */
  /* max-width: 300px; */
  text-align: center;
}

.info-footer {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 0.9em;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}

.info-logo {
  max-width: 600px; /* Imposta la larghezza massima desiderata */
  width: 100%; /* Adatta l'immagine al contenitore */
  height: auto; /* Mantiene le proporzioni */
}

/* OffersAnalysisComplete */

.off-an-container {
  display: flex;
  align-items: center;
  padding: 0.2% 1%;
}

.off-an-head-left {
  display: inline-block;
  width: 25%;
  text-align: left;
}

.off-an-head-center {
  display: inline-block;
  width: 50%;
  text-align: center;
}

.off-an-head-right {
  display: inline-block;
  width: 25%;
  text-align: right;
}

.off-an-title {
  font-family: Calibri, sans-serif;
  font-weight: bold;
  color: #1976d2;
  text-align: center;
}

.off-an-text {
  font-family: Calibri, sans-serif;
  font-weight: bold;
  color: #1976d2;
  text-align: center;
  margin: 1% 0%;
}

.off-an-loading {
  text-align: center;
  padding-top: 2%;
}

.off-an-error {
  font-family: Calibri, sans-serif;
  padding-top: 2%;
  color: #f00;
}
